export default {
	name: "doctor",
	data() {
		return {
			getPatient: {
				page: 1,
				size: 10,
				userType: Number,
				uuid: 'string',
				account:'',
				//登录用户uuid
				patientUuid:'',
				name:null,
				hospitalName:'',
				hospitalCode: '',
			},
			hospitalName: {
				name: '上海'
			},
			allHospital: [],
			tableData: [],
			operateList: [],
			total: 0,
			//关联患者
			doctorShow: Number,
			//禁用按钮
			statusShow: Number,
			//解绑状态
			unbundleShow: Number,
			//登录用户类型
			userType:'',
		}
	},
	created() {
		this.getAllHospital();
		this.userType = sessionStorage.getItem('userType');
		this.getPatient.patientUuid = sessionStorage.getItem('userUuid');
		this.getPatientList();
	},
	methods: {
		//医院接口
		getAllHospital() {
			console.log(this.hospitalName)
			let vm = this;
			this.$axios({
				method: "post",
				url: "/api/web/hospital/page", // 接口地址
				data: vm.hospitalName
			}).then(res => {
				if (res.data.code === 200) {
					vm.allHospital = res.data.data;
					if (vm.allHospital != null) {
						vm.allHospital.forEach(function(item, index) {
							vm.$set(item, 'value', []);
							vm.allHospital[index].value = vm.allHospital[index].name;
						})
					}
				} else {
					this.$message({
						message: "操作失败",
						type: "error",
					});
				}
			}).catch(error => {
				console.log(error);
				//this.$message.error("请求失败");
			});
		},
		hospitalSelect(item) {
			console.log(item)
			if(item == ''){
				console.log(121)
			}else{
				this.hospitalName.name = item;
			}
			this.getAllHospital();
			console.log(this.hospitalName)
		},
		hospitalSearch(queryString, cb) {
			var allHospital = this.allHospital;
			var results = queryString ? allHospital.filter(this.createFilter(queryString)) : allHospital;
			// 调用 callback 返回建议列表的数据
			cb(results);
		},
		createFilter(queryString) {
			return (restaurant) => {
				return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
			};
		},
		//审核带参跳转页面
		doIsVerifiedy(uuid){
			this.$router.push({
				path: 'doctor-detail',
				query: {
					getDoctorManageUuid: uuid,
				}
			});
		},
		//解绑医生
		dostartUnbundle(uuid) {
			console.log(uuid)
			this.$confirm("您确定解除和该医生的绑定吗?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				lockScroll: false,
				type: "warning"
			}).then(() => {
				this.$axios.get('/api/web/patientDoctor/unbind/' + this.getPatient.patientUuid + '/' + uuid).then(res => {
					if (res.data.code === 200) {
						this.getPatientList();
					}
					this.$message.success({
						message: res.data.message,
					});
				}).catch(error => {
					console.log(error);
					//this.$message.error("请求失败");
				});
			}).catch(() => {});
		},
		// 监听 pagesize 改变的事件
		handleSizeChange(newSize) {
			this.getPatient.size = newSize;
			this.getPatientList()
		},
		//监听 页码值 改变的事件
		handleCurrentChange(newPage) {
			this.getPatient.page = newPage;
			this.getPatientList()
		},
		/*获取医生列表数据*/
		getPatientList() {
			if(this.getPatient.hospitalName == ''){
				this.getPatient.hospitalCode=''
			}else if(this.allHospital.length === 1 && this.allHospital[0].name === this.getPatient.hospitalName && this.allHospital[0].hospitalUnifiedSocialCreditCode !== ''){
				this.getPatient.hospitalCode = this.allHospital[0].code;
			}else if(this.allHospital.length === 0){
				this.getPatient.hospitalCode="abc"
			}else{
				this.getPatient.hospitalCode=''
			}
			console.log(this.getPatient)
			this.tableData.splice(0, this.tableData.length); //清空数组
			this.$axios({
				method: "post",
				url: "/api/web/doctor/myDoctorPage", // 接口地址
				data: this.getPatient,
			}).then(res => {
				if (res.data.code === 200) {
					this.tableData = res.data.data;
					this.totalPages = res.data.pageData.totalPages;
					this.total = res.data.pageData.totalElements;
					console.log(this.tableData )
				} else {
					this.$message({
						message: "操作失败",
						type: "error",
					});
				}
			}).catch(error => {
				console.log(error);
				//this.$message.error("请求失败");
			});
		},
	}
}
